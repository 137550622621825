import axios from 'axios';
import swal from 'sweetalert';


export const axiosClient = axios.create({
  baseURL: "https://stagecore.spotbooking.in/api"
  // baseURL: "https://core.spotbooking.in/api"
});

// Set the AUTH token for any request
axiosClient.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token');
  config.headers.Authorization =  token ? `Bearer ${token}` : '';
  return config;
});


axiosClient.interceptors.response.use(
  (response) => {
    if(response.data?.error){
      swal("Error", `${response.data.error}`, "error");
      return Promise.reject('Error: ' + response.data.error);
    }
    return response;
    
  },
  (err) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // const status = err.response?.status || 500;
    // swal("Error", `Some Error Occured. Please Reload`, "error");
    return Promise.reject('Error: ' + err);
    // we can handle global errors here
    // switch (status) {
    //   // authentication (token related issues)
    //   case 401: {
    //     return Promise.reject(new APIError(err.message, 409));
    //   }

    //   // forbidden (permission related issues)
    //   case 403: {
    //     return Promise.reject(new APIError(err.message, 409));
    //   }

    //   // bad request
    //   case 400: {
    //     return Promise.reject(new APIError(err.message, 400));
    //   }

    //   // not found
    //   case 404: {
    //     return Promise.reject(new APIError(err.message, 404));
    //   }

    //   // conflict
    //   case 409: {
    //     return Promise.reject(new APIError(err.message, 409));
    //   }

    //   // unprocessable
    //   case 422: {
    //     return Promise.reject(new APIError(err.message, 422));
    //   }

    //   // generic api error (server related) unexpected
    //   default: {
    //     return Promise.reject(new APIError(err.message, 500));
    //   }
    // }
  }
);